export * from "ce/pages/Editor/gitSync/useReconnectModalData";
import { default as useCEReconnectModalData } from "ce/pages/Editor/gitSync/useReconnectModalData";
import {
  RECONNECT_MISSING_DATASOURCE_CREDENTIALS_PKG_DESCRIPTION,
  SKIP_TO_PACKAGE,
  createMessage,
} from "ee/constants/messages";
import { getImportedPackage } from "ee/selectors/workspaceSelectors";
import { useSelector } from "react-redux";
import { currentPackageEditorURL } from "ee/RouteBuilder";
import { IDE_TYPE } from "ee/entities/IDE/constants";

interface UseReconnectModalDataProps {
  pageId: string | null;
  appId: string | null;
}

function useReconnectModalData({ appId, pageId }: UseReconnectModalDataProps) {
  const importedPackage = useSelector(getImportedPackage);
  const ceData = useCEReconnectModalData({ appId, pageId });

  if (!importedPackage) {
    return ceData;
  }

  const { id } = importedPackage;
  const editorURL = currentPackageEditorURL({ baseModuleId: "" });

  return {
    skipMessage: createMessage(SKIP_TO_PACKAGE),
    missingDsCredentialsDescription: createMessage(
      RECONNECT_MISSING_DATASOURCE_CREDENTIALS_PKG_DESCRIPTION,
    ),
    editorURL,
    editorId: id,
    parentEntityId: null,
    ideType: IDE_TYPE.Package,
  };
}

export default useReconnectModalData;
